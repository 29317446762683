/* eslint-disable */
import { jsPDF } from "jspdf";
import "jspdf-autotable";

import headerImg from "../assets/header.jpg";
import footerImg from "../assets/footer.jpg";
import policy from "../assets/policy.png";
import coverImg from "../assets/invoice_cover.png";

// Set the number format
const c = Intl.NumberFormat("nl-NL", { style: "currency", currency: "EUR" });

const GenerateInvoice = (prodData, assembly, user) => {
  const pdf = new jsPDF();
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm_today = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();

  // Clone today to avoid modifying it directly
  var term = new Date(today.getTime());

  // Calculate the date 7 days from today
  term.setDate(today.getDate() + 7);

  var ddt = String(term.getDate()).padStart(2, "0");
  var mm = String(term.getMonth() + 1).padStart(2, "0");
  var yyyy = term.getFullYear();

  // Ensure that term doesn't exceed the last day of the month
  if (term.getMonth() !== today.getMonth()) {
    term.setDate(0); // Set term to the last day of the current month
  }

  // Construct the date strings
  today = dd + "/" + mm_today + "/" + yyyy;
  term = ddt + "/" + mm + "/" + yyyy;

  let head = [
    ["Index", "Product", "MSRP", "Korting", "Prijs", "Aantal", "Subtotaal"],
  ];

  let headLevering = [["Leveringskosten:"]];

  const columns = [
    { header: "Index", dataKey: "index" },
    { header: "Product", dataKey: "product" },
    { header: "MSRP", dataKey: "msrp" },
    { header: "Korting", dataKey: "korting" },
    { header: "Prijs", dataKey: "prijs" },
    { header: "Aantal", dataKey: "aantal" },
    { header: "Subtotaal", dataKey: "subtotaal" },
  ];

  const body = prodData.products.map((row, index) => {
    const brutoPrijs = parseFloat(
      row.brutoprijs.replace(/[.]/g, "").replace(",", ".")
    );
    const korting = Number(row.korting.replace(/%/, ""));

    return {
      index: index + 1,
      product: `${row.omschrijving}\n\nModel: ${row.id}\nMerk: ${row.merk}`,
      msrp: c.format((brutoPrijs / (100 - korting)) * 100),
      korting: row.korting,
      prijs: c.format(Number(brutoPrijs)),
      aantal: row.qty,
      subtotaal: c.format(row.qty * Number(brutoPrijs)),
    };
  });

  let totaal = prodData.products.reduce((sum, row) => {
    const brutoPrijs = parseFloat(
      row.brutoprijs.replace(/[.]/g, "").replace(",", ".")
    );
    sum += brutoPrijs * row.qty;
    return sum;
  }, 0);

  pdf.html("<div></div>", {
    callback: function () {
      let counter = localStorage.getItem("counter");
      pdf.setPage(1);
      pdf.addImage(headerImg, "JPEG", 0, 0, 210, 42, undefined, "FAST");
      pdf.addImage(coverImg, "PNG", 55, 0, 160, 175, undefined, "FAST");
      pdf.setLineWidth(0.5);
      pdf.line(10, 180, 196, 180);
      pdf.autoTable({
        body: [
          ["Offertedatum :", today],
          ["Vervaldatum :", term],
          ["", ""],
          [
            "Onze referentie :",
            user?.nickname +
              "-" +
              "MSB" +
              "-" +
              yyyy +
              "-" +
              counter +
              "-" +
              Math.floor(Math.random() * 101),
          ],
          ["", ""],
          ["Uw contactpersoon :", user?.email],
          ["Projectomschrijving  :", prodData.description],
        ],
        theme: "grid",
        margin: { top: 185, left: 10, bottom: 10 },
        bodyStyles: { lineColor: [0, 0, 0] },
      });
      pdf.addImage(footerImg, "JPEG", -5, 267, 215, 33, undefined, "FAST");
      pdf.addPage();
      pdf.deletePage(2);

      pdf.autoTable({
        head,
        headStyles: {
          fillColor: "#AA0054",
          valign: "middle",
          halign: "center",
        },
        columns,
        columnStyles: {
          2: { minCellWidth: 25 },
          3: { maxCellWidth: 15 },
          4: { minCellWidth: 30 },
          5: { maxCellWidth: 5 },
          6: { minCellWidth: 30 },
        },
        body,
        bodyStyles: { lineColor: [0, 0, 0], cellWidth: "auto" },
        showFoot: "lastPage",
        foot: [
          [
            "",
            "",
            "",
            "",
            "",
            "TOTAAL + LEVERING:",
            totaal < 1500 && assembly === 0
              ? c.format((totaal += 18))
              : c.format(totaal),
          ],
        ],
        theme: "grid",
        margin: { top: 42, left: 10, bottom: 30 },

        footStyles: {
          halign: "right",
          fillColor: "#AA0054",
          cellWidth: "auto",
        },

        didDrawPage: ({ doc }) => {
          doc.addImage(headerImg, "JPEG", 0, 0, 210, 42, undefined, "FAST");
          doc.addImage(footerImg, "JPEG", -5, 267, 215, 33, undefined, "FAST");
        },
      });
      if (totaal < 1500 && !assembly) {
        head = headLevering;
        pdf.autoTable({
          head,
          headStyles: {
            fillColor: "#AA0054",
            valign: "middle",
            halign: "left",
          },

          body: [["€18"]],
          theme: "grid",
          margin: { top: 35, left: 10 },
          bodyStyles: { lineColor: [0, 0, 0], halign: "right" },
          columnStyles: { 1: { halign: "right" } },
        });
      }

      if (assembly) {
        pdf.addPage();
        pdf.addImage(headerImg, "JPEG", 0, 0, 210, 42, undefined, "FAST");
        pdf.setFontSize(9);
        pdf.autoTable({
          body: [
            [
              "Montage:\n\n  - Installatie van AV componenten \n  - Afwerken bekabeling \n  - In dienst stellen ruimte \n  - Incl.Projectleiding \n  - Incl.Klein materiaal",
              c.format(totaal * 0.15),
            ],
          ],
          foot: [["TOTAAL (incl. montage):", c.format(totaal * 1.15)]],
          theme: "grid",
          margin: { top: 35, left: 10 },
          bodyStyles: { lineColor: [0, 0, 0] },
          footStyles: { halign: "right", fillColor: "#AA0054" },
          columnStyles: { 1: { halign: "right" } },
        });
        pdf.addImage(footerImg, "JPEG", -5, 267, 215, 33, undefined, "FAST");
      }
      pdf.addPage();
      pdf.addImage(policy, "png", 0, 0, 210, 300, undefined, "FAST");
      pdf.save(`MSB-Offerte_${today}`);
    },

    x: 10,
    y: 320,
    margin: [0, 0, 32, 0],
    width: 190,
    windowWidth: 800,
  });
};

export { GenerateInvoice };
