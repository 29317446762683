//@ts-nocheck
/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 *
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1.1
 *
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from "url";
import * as isomorphicFetch from "isomorphic-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "/".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ",",
  ssv: " ",
  tsv: "	",
  pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
  (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
  url: string;
  options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration;

  constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected fetch: FetchAPI = isomorphicFetch
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  name: "RequiredError";
  constructor(public field: string, msg?: string) {
    super(msg);
  }
}

/**
 *
 * @export
 * @interface BrandsArray
 */
export interface BrandsArray {}
/**
 *
 * @export
 * @interface CatsArray
 */
export interface CatsArray {}
/**
 * Creates an order for the logged in customer
 * @export
 * @interface CreateOrderResponse
 */
export interface CreateOrderResponse {
  /**
   *
   * @type {ListOrderResponseData}
   * @memberof CreateOrderResponse
   */
  data: ListOrderResponseData;
  /**
   *
   * @type {ListOrderResponseLinks}
   * @memberof CreateOrderResponse
   */
  links: ListOrderResponseLinks;
}
/**
 * Gets a list of orders for the logged in customer
 * @export
 * @interface ListOrderResponse
 */
export interface ListOrderResponse {
  /**
   *
   * @type {ListOrderResponsePage}
   * @memberof ListOrderResponse
   */
  page: ListOrderResponsePage;
  /**
   *
   * @type {Array<ListOrderResponseData>}
   * @memberof ListOrderResponse
   */
  data: Array<ListOrderResponseData>;
  /**
   *
   * @type {ListOrderResponseLinks}
   * @memberof ListOrderResponse
   */
  links: ListOrderResponseLinks;
}
/**
 *
 * @export
 * @interface ListOrderResponseData
 */
export interface ListOrderResponseData {
  /**
   *
   * @type {string}
   * @memberof ListOrderResponseData
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof ListOrderResponseData
   */
  numericId: number;
  /**
   *
   * @type {Date}
   * @memberof ListOrderResponseData
   */
  date: Date;
  /**
   *
   * @type {string}
   * @memberof ListOrderResponseData
   */
  value: string;
  /**
   *
   * @type {string | ModelNull}
   * @memberof ListOrderResponseData
   */
  paymentId: string | ModelNull;
  /**
   *
   * @type {string}
   * @memberof ListOrderResponseData
   */
  status: string;
  /**
   *
   * @type {Array<ListOrderResponseProducts>}
   * @memberof ListOrderResponseData
   */
  products?: Array<ListOrderResponseProducts>;
}
/**
 *
 * @export
 * @interface ListOrderResponseLinks
 */
export interface ListOrderResponseLinks {
  /**
   *
   * @type {string}
   * @memberof ListOrderResponseLinks
   */
  self: string;
  /**
   *
   * @type {string}
   * @memberof ListOrderResponseLinks
   */
  previous?: string;
  /**
   *
   * @type {string}
   * @memberof ListOrderResponseLinks
   */
  next?: string;
}
/**
 *
 * @export
 * @interface ListOrderResponsePage
 */
export interface ListOrderResponsePage {
  /**
   *
   * @type {number}
   * @memberof ListOrderResponsePage
   */
  size: number;
  /**
   *
   * @type {number}
   * @memberof ListOrderResponsePage
   */
  totalElements: number;
  /**
   *
   * @type {number}
   * @memberof ListOrderResponsePage
   */
  totalPages: number;
  /**
   *
   * @type {number}
   * @memberof ListOrderResponsePage
   */
  number: number;
}
/**
 *
 * @export
 * @interface ListOrderResponseProducts
 */
export interface ListOrderResponseProducts {
  /**
   *
   * @type {string}
   * @memberof ListOrderResponseProducts
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ListOrderResponseProducts
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ListOrderResponseProducts
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof ListOrderResponseProducts
   */
  category: string;
  /**
   *
   * @type {string}
   * @memberof ListOrderResponseProducts
   */
  model: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ListOrderResponseProducts
   */
  manufacturers: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ListOrderResponseProducts
   */
  distributors: Array<string>;
  /**
   *
   * @type {string | ModelNull}
   * @memberof ListOrderResponseProducts
   */
  pros: string | ModelNull;
  /**
   *
   * @type {string | ModelNull}
   * @memberof ListOrderResponseProducts
   */
  cons: string | ModelNull;
  /**
   *
   * @type {string}
   * @memberof ListOrderResponseProducts
   */
  recommendedPrice: string;
  /**
   *
   * @type {string}
   * @memberof ListOrderResponseProducts
   */
  wholesalePrice: string;
  /**
   *
   * @type {string}
   * @memberof ListOrderResponseProducts
   */
  onlinePrice: string;
  /**
   *
   * @type {number}
   * @memberof ListOrderResponseProducts
   */
  labour: number;
}
/**
 * Updates database CSV
 * @export
 * @interface ListPortalProductResponse
 */
export interface ListPortalProductResponse {
  /**
   *
   * @type {ListOrderResponsePage}
   * @memberof ListPortalProductResponse
   */
  page: ListOrderResponsePage;
  /**
   *
   * @type {Array<PortalProductResponseData>}
   * @memberof ListPortalProductResponse
   */
  data: Array<PortalProductResponseData>;
  /**
   *
   * @type {ListOrderResponseLinks}
   * @memberof ListPortalProductResponse
   */
  links: ListOrderResponseLinks;
}
/**
 * Gets notes
 * @export
 * @interface ListPortalProductResponse1
 */
export interface ListPortalProductResponse1 {
  /**
   *
   * @type {ListOrderResponsePage}
   * @memberof ListPortalProductResponse1
   */
  page: ListOrderResponsePage;
  /**
   *
   * @type {Array<PortalProductResponseData>}
   * @memberof ListPortalProductResponse1
   */
  data: Array<PortalProductResponseData>;
  /**
   *
   * @type {ListOrderResponseLinks}
   * @memberof ListPortalProductResponse1
   */
  links: ListOrderResponseLinks;
}
/**
 * Gets a list of Products for the logged in customer
 * @export
 * @interface ListPortalProductResponse2
 */
export interface ListPortalProductResponse2 {
  /**
   *
   * @type {ListOrderResponsePage}
   * @memberof ListPortalProductResponse2
   */
  page: ListOrderResponsePage;
  /**
   *
   * @type {Array<PortalProductResponseData>}
   * @memberof ListPortalProductResponse2
   */
  data: Array<PortalProductResponseData>;
  /**
   *
   * @type {ListOrderResponseLinks}
   * @memberof ListPortalProductResponse2
   */
  links: ListOrderResponseLinks;
}
/**
 * Gets a list of Products for the logged in customer
 * @export
 * @interface ListProductResponse
 */
export interface ListProductResponse {
  /**
   *
   * @type {ListOrderResponsePage}
   * @memberof ListProductResponse
   */
  page: ListOrderResponsePage;
  /**
   *
   * @type {Array<ListOrderResponseProducts>}
   * @memberof ListProductResponse
   */
  data: Array<ListOrderResponseProducts>;
  /**
   *
   * @type {ListOrderResponseLinks}
   * @memberof ListProductResponse
   */
  links: ListOrderResponseLinks;
}
/**
 * Gets a list of questions
 * @export
 * @interface ListQuestionsResponse
 */
export interface ListQuestionsResponse {
  /**
   *
   * @type {ListOrderResponsePage}
   * @memberof ListQuestionsResponse
   */
  page: ListOrderResponsePage;
  /**
   *
   * @type {Array<ListQuestionsResponseData>}
   * @memberof ListQuestionsResponse
   */
  data: Array<ListQuestionsResponseData>;
  /**
   *
   * @type {ListOrderResponseLinks}
   * @memberof ListQuestionsResponse
   */
  links: ListOrderResponseLinks;
}
/**
 *
 * @export
 * @interface ListQuestionsResponseAnswers
 */
export interface ListQuestionsResponseAnswers {
  /**
   *
   * @type {string}
   * @memberof ListQuestionsResponseAnswers
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ListQuestionsResponseAnswers
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ListQuestionsResponseAnswers
   */
  title: string;
  /**
   *
   * @type {string | ModelNull}
   * @memberof ListQuestionsResponseAnswers
   */
  nextId: string | ModelNull;
  /**
   *
   * @type {number}
   * @memberof ListQuestionsResponseAnswers
   */
  value: number;
}
/**
 *
 * @export
 * @interface ListQuestionsResponseData
 */
export interface ListQuestionsResponseData {
  /**
   *
   * @type {string}
   * @memberof ListQuestionsResponseData
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ListQuestionsResponseData
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof ListQuestionsResponseData
   */
  body: string;
  /**
   *
   * @type {Array<ListQuestionsResponseAnswers>}
   * @memberof ListQuestionsResponseData
   */
  answers: Array<ListQuestionsResponseAnswers>;
}
/**
 * Gets an order by id
 * @export
 * @interface OrderResponse
 */
export interface OrderResponse {
  /**
   *
   * @type {ListOrderResponseData}
   * @memberof OrderResponse
   */
  data: ListOrderResponseData;
  /**
   *
   * @type {ListOrderResponseLinks}
   * @memberof OrderResponse
   */
  links: ListOrderResponseLinks;
}
/**
 * Gets brands
 * @export
 * @interface PortalProductResponse
 */
export interface PortalProductResponse {
  /**
   *
   * @type {ListOrderResponsePage}
   * @memberof PortalProductResponse
   */
  page: ListOrderResponsePage;
  /**
   *
   * @type {Array<PortalProductResponseData>}
   * @memberof PortalProductResponse
   */
  data: Array<PortalProductResponseData>;
  /**
   *
   * @type {ListOrderResponseLinks}
   * @memberof PortalProductResponse
   */
  links: ListOrderResponseLinks;
}
/**
 * Gets categories
 * @export
 * @interface PortalProductResponse1
 */
export interface PortalProductResponse1 {
  /**
   *
   * @type {ListOrderResponsePage}
   * @memberof PortalProductResponse1
   */
  page: ListOrderResponsePage;
  /**
   *
   * @type {Array<PortalProductResponseData>}
   * @memberof PortalProductResponse1
   */
  data: Array<PortalProductResponseData>;
  /**
   *
   * @type {ListOrderResponseLinks}
   * @memberof PortalProductResponse1
   */
  links: ListOrderResponseLinks;
}
/**
 *
 * @export
 * @interface PortalProductResponseData
 */
export interface PortalProductResponseData {
  /**
   *
   * @type {string}
   * @memberof PortalProductResponseData
   */
  id: string;
  /**
   *
   * @type {string | ModelNull}
   * @memberof PortalProductResponseData
   */
  omschrijving: string | ModelNull;
  /**
   *
   * @type {string}
   * @memberof PortalProductResponseData
   */
  merk: string;
  /**
   *
   * @type {string}
   * @memberof PortalProductResponseData
   */
  brutoprijs: string;
  /**
   *
   * @type {string | ModelNull}
   * @memberof PortalProductResponseData
   */
  type?: string | ModelNull;
  /**
   *
   * @type {string | ModelNull}
   * @memberof PortalProductResponseData
   */
  korting?: string | ModelNull;
  /**
   *
   * @type {string | ModelNull}
   * @memberof PortalProductResponseData
   */
  opmerkingen?: string | ModelNull;
  /**
   *
   * @type {string | ModelNull}
   * @memberof PortalProductResponseData
   */
  klant?: string | ModelNull;
}
/**
 *
 * @export
 * @interface PortalproductUploadBody
 */
export interface PortalproductUploadBody {
  /**
   *
   * @type {string}
   * @memberof PortalproductUploadBody
   */
  content?: string;
}
/**
 *
 * @export
 * @interface ProductRecommendationBody
 */
export interface ProductRecommendationBody {
  /**
   *
   * @type {Array<string>}
   * @memberof ProductRecommendationBody
   */
  answers: Array<string>;
}
/**
 * Gets a single product
 * @export
 * @interface ProductResponse
 */
export interface ProductResponse {
  /**
   *
   * @type {ListOrderResponseProducts}
   * @memberof ProductResponse
   */
  data: ListOrderResponseProducts;
  /**
   *
   * @type {ListOrderResponseLinks}
   * @memberof ProductResponse
   */
  links: ListOrderResponseLinks;
}
/**
 * Gets a single product
 * @export
 * @interface RecommendationResponse
 */
export interface RecommendationResponse {
  /**
   *
   * @type {ListOrderResponsePage}
   * @memberof RecommendationResponse
   */
  page: ListOrderResponsePage;
  /**
   *
   * @type {Array<ListOrderResponseProducts>}
   * @memberof RecommendationResponse
   */
  data: Array<ListOrderResponseProducts>;
  /**
   *
   * @type {ListOrderResponseLinks}
   * @memberof RecommendationResponse
   */
  links: ListOrderResponseLinks;
}
/**
 *
 * @export
 * @interface V1OrderBody
 */
export interface V1OrderBody {
  /**
   *
   * @type {Array<string>}
   * @memberof V1OrderBody
   */
  products: Array<string>;
}
/**
 * CheckoutApi - fetch parameter creator
 * @export
 */
export const CheckoutApiFetchParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {string} orderId
     * @param {string} authorization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkout(
      orderId: string,
      authorization: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'orderId' is not null or undefined
      if (orderId === null || orderId === undefined) {
        throw new RequiredError(
          "orderId",
          "Required parameter orderId was null or undefined when calling checkout."
        );
      }
      // verify required parameter 'authorization' is not null or undefined
      if (authorization === null || authorization === undefined) {
        throw new RequiredError(
          "authorization",
          "Required parameter authorization was null or undefined when calling checkout."
        );
      }
      const localVarPath = `/v1/order/{orderId}/checkout`.replace(
        `{${"orderId"}}`,
        encodeURIComponent(String(orderId))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization !== undefined && authorization !== null) {
        localVarHeaderParameter["authorization"] = String(authorization);
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CheckoutApi - functional programming interface
 * @export
 */
export const CheckoutApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} orderId
     * @param {string} authorization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkout(
      orderId: string,
      authorization: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = CheckoutApiFetchParamCreator(
        configuration
      ).checkout(orderId, authorization, options);
      return (
        fetch: FetchAPI = isomorphicFetch,
        basePath: string = BASE_PATH
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * CheckoutApi - factory interface
 * @export
 */
export const CheckoutApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @param {string} orderId
     * @param {string} authorization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkout(orderId: string, authorization: string, options?: any) {
      return CheckoutApiFp(configuration).checkout(
        orderId,
        authorization,
        options
      )(fetch, basePath);
    },
  };
};

/**
 * CheckoutApi - object-oriented interface
 * @export
 * @class CheckoutApi
 * @extends {BaseAPI}
 */
export class CheckoutApi extends BaseAPI {
  /**
   *
   * @param {string} orderId
   * @param {string} authorization
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CheckoutApi
   */
  public checkout(orderId: string, authorization: string, options?: any) {
    return CheckoutApiFp(this.configuration).checkout(
      orderId,
      authorization,
      options
    )(this.fetch, this.basePath);
  }
}
/**
 * OrderApi - fetch parameter creator
 * @export
 */
export const OrderApiFetchParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {V1OrderBody} body
     * @param {string} authorization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(
      body: V1OrderBody,
      authorization: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling create."
        );
      }
      // verify required parameter 'authorization' is not null or undefined
      if (authorization === null || authorization === undefined) {
        throw new RequiredError(
          "authorization",
          "Required parameter authorization was null or undefined when calling create."
        );
      }
      const localVarPath = `/v1/order`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization !== undefined && authorization !== null) {
        localVarHeaderParameter["authorization"] = String(authorization);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers
      );
      const needsSerialization =
        <any>"V1OrderBody" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(body || {})
        : body || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orderId
     * @param {string} authorization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getById(
      orderId: string,
      authorization: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'orderId' is not null or undefined
      if (orderId === null || orderId === undefined) {
        throw new RequiredError(
          "orderId",
          "Required parameter orderId was null or undefined when calling getById."
        );
      }
      // verify required parameter 'authorization' is not null or undefined
      if (authorization === null || authorization === undefined) {
        throw new RequiredError(
          "authorization",
          "Required parameter authorization was null or undefined when calling getById."
        );
      }
      const localVarPath = `/v1/order/{orderId}`.replace(
        `{${"orderId"}}`,
        encodeURIComponent(String(orderId))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization !== undefined && authorization !== null) {
        localVarHeaderParameter["authorization"] = String(authorization);
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} authorization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    list(authorization: string, options: any = {}): FetchArgs {
      // verify required parameter 'authorization' is not null or undefined
      if (authorization === null || authorization === undefined) {
        throw new RequiredError(
          "authorization",
          "Required parameter authorization was null or undefined when calling list."
        );
      }
      const localVarPath = `/v1/order`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization !== undefined && authorization !== null) {
        localVarHeaderParameter["authorization"] = String(authorization);
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OrderApi - functional programming interface
 * @export
 */
export const OrderApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {V1OrderBody} body
     * @param {string} authorization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(
      body: V1OrderBody,
      authorization: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CreateOrderResponse> {
      const localVarFetchArgs = OrderApiFetchParamCreator(configuration).create(
        body,
        authorization,
        options
      );
      return (
        fetch: FetchAPI = isomorphicFetch,
        basePath: string = BASE_PATH
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @param {string} orderId
     * @param {string} authorization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getById(
      orderId: string,
      authorization: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<OrderResponse> {
      const localVarFetchArgs = OrderApiFetchParamCreator(
        configuration
      ).getById(orderId, authorization, options);
      return (
        fetch: FetchAPI = isomorphicFetch,
        basePath: string = BASE_PATH
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @param {string} authorization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    list(
      authorization: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<ListOrderResponse> {
      const localVarFetchArgs = OrderApiFetchParamCreator(configuration).list(
        authorization,
        options
      );
      return (
        fetch: FetchAPI = isomorphicFetch,
        basePath: string = BASE_PATH
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * OrderApi - factory interface
 * @export
 */
export const OrderApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @param {V1OrderBody} body
     * @param {string} authorization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: V1OrderBody, authorization: string, options?: any) {
      return OrderApiFp(configuration).create(
        body,
        authorization,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @param {string} orderId
     * @param {string} authorization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getById(orderId: string, authorization: string, options?: any) {
      return OrderApiFp(configuration).getById(
        orderId,
        authorization,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @param {string} authorization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    list(authorization: string, options?: any) {
      return OrderApiFp(configuration).list(authorization, options)(
        fetch,
        basePath
      );
    },
  };
};

/**
 * OrderApi - object-oriented interface
 * @export
 * @class OrderApi
 * @extends {BaseAPI}
 */
export class OrderApi extends BaseAPI {
  /**
   *
   * @param {V1OrderBody} body
   * @param {string} authorization
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderApi
   */
  public create(body: V1OrderBody, authorization: string, options?: any) {
    return OrderApiFp(this.configuration).create(
      body,
      authorization,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @param {string} orderId
   * @param {string} authorization
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderApi
   */
  public getById(orderId: string, authorization: string, options?: any) {
    return OrderApiFp(this.configuration).getById(
      orderId,
      authorization,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @param {string} authorization
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderApi
   */
  public list(authorization: string, options?: any) {
    return OrderApiFp(this.configuration).list(authorization, options)(
      this.fetch,
      this.basePath
    );
  }
}
/**
 * PortalProductApi - fetch parameter creator
 * @export
 */
export const PortalProductApiFetchParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrands(options: any = {}): FetchArgs {
      const localVarPath = `/v1/portalproduct/brands`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCats(options: any = {}): FetchArgs {
      const localVarPath = `/v1/portalproduct/cat`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotes(options: any = {}): FetchArgs {
      const localVarPath = `/v1/portalproduct/notes`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [userId]
     * @param {string} [search]
     * @param {BrandsArray} [brandsArray]
     * @param {CatsArray} [catsArray]
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    list(
      userId?: string,
      search?: string,
      brandsArray?: BrandsArray,
      catsArray?: CatsArray,
      page?: number,
      size?: number,
      options: any = {}
    ): FetchArgs {
      const localVarPath = `/v1/portalproduct`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (userId !== undefined) {
        localVarQueryParameter["userId"] = userId;
      }

      if (search !== undefined) {
        localVarQueryParameter["search"] = search;
      }

      if (brandsArray !== undefined) {
        localVarQueryParameter["brandsArray"] = brandsArray;
      }

      if (catsArray !== undefined) {
        localVarQueryParameter["catsArray"] = catsArray;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [content]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postFile(content?: string, options: any = {}): FetchArgs {
      const localVarPath = `/v1/portalproduct/upload`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new URLSearchParams();

      if (content !== undefined) {
        localVarFormParams.set("content", content as any);
      }

      localVarHeaderParameter["Content-Type"] =
        "application/x-www-form-urlencoded";

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers
      );
      localVarRequestOptions.body = localVarFormParams.toString();

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PortalProductApi - functional programming interface
 * @export
 */
export const PortalProductApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrands(
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<PortalProductResponse> {
      const localVarFetchArgs =
        PortalProductApiFetchParamCreator(configuration).getBrands(options);
      return (
        fetch: FetchAPI = isomorphicFetch,
        basePath: string = BASE_PATH
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCats(
      options?: any
    ): (
      fetch?: FetchAPI,
      basePath?: string
    ) => Promise<PortalProductResponse1> {
      const localVarFetchArgs =
        PortalProductApiFetchParamCreator(configuration).getCats(options);
      return (
        fetch: FetchAPI = isomorphicFetch,
        basePath: string = BASE_PATH
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotes(
      options?: any
    ): (
      fetch?: FetchAPI,
      basePath?: string
    ) => Promise<ListPortalProductResponse1> {
      const localVarFetchArgs =
        PortalProductApiFetchParamCreator(configuration).getNotes(options);
      return (
        fetch: FetchAPI = isomorphicFetch,
        basePath: string = BASE_PATH
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @param {string} [userId]
     * @param {string} [search]
     * @param {BrandsArray} [brandsArray]
     * @param {CatsArray} [catsArray]
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    list(
      userId?: string,
      search?: string,
      brandsArray?: BrandsArray,
      catsArray?: CatsArray,
      page?: number,
      size?: number,
      options?: any
    ): (
      fetch?: FetchAPI,
      basePath?: string
    ) => Promise<ListPortalProductResponse2> {
      const localVarFetchArgs = PortalProductApiFetchParamCreator(
        configuration
      ).list(userId, search, brandsArray, catsArray, page, size, options);
      return (
        fetch: FetchAPI = isomorphicFetch,
        basePath: string = BASE_PATH
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @param {string} [content]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postFile(
      content?: string,
      options?: any
    ): (
      fetch?: FetchAPI,
      basePath?: string
    ) => Promise<ListPortalProductResponse> {
      const localVarFetchArgs = PortalProductApiFetchParamCreator(
        configuration
      ).postFile(content, options);
      return (
        fetch: FetchAPI = isomorphicFetch,
        basePath: string = BASE_PATH
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * PortalProductApi - factory interface
 * @export
 */
export const PortalProductApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrands(options?: any) {
      return PortalProductApiFp(configuration).getBrands(options)(
        fetch,
        basePath
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCats(options?: any) {
      return PortalProductApiFp(configuration).getCats(options)(
        fetch,
        basePath
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotes(options?: any) {
      return PortalProductApiFp(configuration).getNotes(options)(
        fetch,
        basePath
      );
    },
    /**
     *
     * @param {string} [userId]
     * @param {string} [search]
     * @param {BrandsArray} [brandsArray]
     * @param {CatsArray} [catsArray]
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    list(
      userId?: string,
      search?: string,
      brandsArray?: BrandsArray,
      catsArray?: CatsArray,
      page?: number,
      size?: number,
      options?: any
    ) {
      return PortalProductApiFp(configuration).list(
        userId,
        search,
        brandsArray,
        catsArray,
        page,
        size,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @param {string} [content]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postFile(content?: string, options?: any) {
      return PortalProductApiFp(configuration).postFile(content, options)(
        fetch,
        basePath
      );
    },
  };
};

/**
 * PortalProductApi - object-oriented interface
 * @export
 * @class PortalProductApi
 * @extends {BaseAPI}
 */
export class PortalProductApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalProductApi
   */
  public getBrands(options?: any) {
    return PortalProductApiFp(this.configuration).getBrands(options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalProductApi
   */
  public getCats(options?: any) {
    return PortalProductApiFp(this.configuration).getCats(options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalProductApi
   */
  public getNotes(options?: any) {
    return PortalProductApiFp(this.configuration).getNotes(options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @param {string} [userId]
   * @param {string} [search]
   * @param {BrandsArray} [brandsArray]
   * @param {CatsArray} [catsArray]
   * @param {number} [page]
   * @param {number} [size]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalProductApi
   */
  public list(
    userId?: string,
    search?: string,
    brandsArray?: BrandsArray,
    catsArray?: CatsArray,
    page?: number,
    size?: number,
    options?: any
  ) {
    return PortalProductApiFp(this.configuration).list(
      userId,
      search,
      brandsArray,
      catsArray,
      page,
      size,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @param {string} [content]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalProductApi
   */
  public postFile(content?: string, options?: any) {
    return PortalProductApiFp(this.configuration).postFile(content, options)(
      this.fetch,
      this.basePath
    );
  }
}
/**
 * ProductApi - fetch parameter creator
 * @export
 */
export const ProductApiFetchParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {string} productId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getById(productId: string, options: any = {}): FetchArgs {
      // verify required parameter 'productId' is not null or undefined
      if (productId === null || productId === undefined) {
        throw new RequiredError(
          "productId",
          "Required parameter productId was null or undefined when calling getById."
        );
      }
      const localVarPath = `/v1/product/{productId}`.replace(
        `{${"ProductId"}}`,
        encodeURIComponent(String(productId))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ProductRecommendationBody} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecommendation(
      body: ProductRecommendationBody,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling getRecommendation."
        );
      }
      const localVarPath = `/v1/product/recommendation`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers
      );
      const needsSerialization =
        <any>"ProductRecommendationBody" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(body || {})
        : body || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    list(options: any = {}): FetchArgs {
      const localVarPath = `/v1/product`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} productId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getById(
      productId: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<ProductResponse> {
      const localVarFetchArgs = ProductApiFetchParamCreator(
        configuration
      ).getById(productId, options);
      return (
        fetch: FetchAPI = isomorphicFetch,
        basePath: string = BASE_PATH
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @param {ProductRecommendationBody} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecommendation(
      body: ProductRecommendationBody,
      options?: any
    ): (
      fetch?: FetchAPI,
      basePath?: string
    ) => Promise<RecommendationResponse> {
      const localVarFetchArgs = ProductApiFetchParamCreator(
        configuration
      ).getRecommendation(body, options);
      return (
        fetch: FetchAPI = isomorphicFetch,
        basePath: string = BASE_PATH
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    list(
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<ListProductResponse> {
      const localVarFetchArgs =
        ProductApiFetchParamCreator(configuration).list(options);
      return (
        fetch: FetchAPI = isomorphicFetch,
        basePath: string = BASE_PATH
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @param {string} productId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getById(productId: string, options?: any) {
      return ProductApiFp(configuration).getById(productId, options)(
        fetch,
        basePath
      );
    },
    /**
     *
     * @param {ProductRecommendationBody} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecommendation(body: ProductRecommendationBody, options?: any) {
      return ProductApiFp(configuration).getRecommendation(body, options)(
        fetch,
        basePath
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    list(options?: any) {
      return ProductApiFp(configuration).list(options)(fetch, basePath);
    },
  };
};

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI {
  /**
   *
   * @param {string} productId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductApi
   */
  public getById(productId: string, options?: any) {
    return ProductApiFp(this.configuration).getById(productId, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @param {ProductRecommendationBody} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductApi
   */
  public getRecommendation(body: ProductRecommendationBody, options?: any) {
    return ProductApiFp(this.configuration).getRecommendation(body, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductApi
   */
  public list(options?: any) {
    return ProductApiFp(this.configuration).list(options)(
      this.fetch,
      this.basePath
    );
  }
}
/**
 * QuestionApi - fetch parameter creator
 * @export
 */
export const QuestionApiFetchParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    list(options: any = {}): FetchArgs {
      const localVarPath = `/v1/question`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * QuestionApi - functional programming interface
 * @export
 */
export const QuestionApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    list(
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<ListQuestionsResponse> {
      const localVarFetchArgs =
        QuestionApiFetchParamCreator(configuration).list(options);
      return (
        fetch: FetchAPI = isomorphicFetch,
        basePath: string = BASE_PATH
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * QuestionApi - factory interface
 * @export
 */
export const QuestionApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    list(options?: any) {
      return QuestionApiFp(configuration).list(options)(fetch, basePath);
    },
  };
};

/**
 * QuestionApi - object-oriented interface
 * @export
 * @class QuestionApi
 * @extends {BaseAPI}
 */
export class QuestionApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApi
   */
  public list(options?: any) {
    return QuestionApiFp(this.configuration).list(options)(
      this.fetch,
      this.basePath
    );
  }
}
