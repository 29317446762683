import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { GenerateInvoice } from "./Invoice";

export function InvoiceGenerator({ state, assembly }) {
  const { user } = useAuth0();

  React.useEffect(() => {
    if (user) {
      GenerateInvoice(state, assembly, user);
    }
  }, [state, assembly, user]);

  return null; // This component doesn't render anything
}
