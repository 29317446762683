import { useState, useEffect } from "react";
import styled from "styled-components";
import { apiClient } from "../../../utils/apiClient";
import { useAuth0 } from "@auth0/auth0-react";

import { fontSizes, colors } from "../../Tokens";
import { Container } from "../../Container";
import { Row, AutoWidth, CustomWidth } from "../../Grid";
import Header from "../../Header";
import { CartDrawer, FilterDrawer, NoteDrawer } from "../../Drawer";
import { FilterPanel } from "../../FilterPanel";
import { PortalProductListing } from "../../PortalProduct";
import { Wrapper } from "../../Wrapper";
import { OrderList } from "../../OrderList";
import Skeleton from "react-loading-skeleton";
import { Title } from "../../Title";
import "react-loading-skeleton/dist/skeleton.css";

const ListContainer = styled(Container)`
  padding-top: 157px;

  @media screen and (min-width: 768px) {
    padding-top: 97px;
  }
  @media screen and (max-width: 768px) {
    align: center;
  }
`;

const Note = styled.div`
  font-size: ${fontSizes.xsmall};
  max-width: 80%;
  background-color: white;
  height: auto;
  white-space: pre-wrap;
  color: ${colors.purple};
  margin-top: 10rem;
  border-radius: 4px;
  position: absolute;
  left: 5rem;
  padding: 2rem 2rem;
  width: 80%;
`;

function Landing() {
  const [cart, setCart] = useState({
    products: [],
    description: null,
    open: false,
  });
  const [filter, setFilter] = useState({ open: false });
  const [note, setNote] = useState({ open: true });
  const [notes, setNotes] = useState([]);
  const [brands, setBrands] = useState([]);
  const [cats, setCats] = useState([]);
  const [currentSearchTerm, setCurrentSearchTerm] = useState("");
  const [products] = useState([]);
  const { isAuthenticated } = useAuth0();

  const functions = {
    openCart: () => setCart((prev) => ({ ...prev, open: !prev.open })),
    openNote: () => setNote((prev) => ({ ...prev, open: !prev.open })),
    openFilter: () => setFilter((prev) => ({ ...prev, open: !prev.open })),
    remove: (index) => {
      setCart((prev) => {
        const newProducts = [...prev.products];
        newProducts.splice(index, 1);
        return { ...prev, products: newProducts };
      });
    },
    clear: () => setCart((prev) => ({ ...prev, products: [] })),
    update: (index, qty) => {
      setCart((prev) => {
        const newProducts = [...prev.products];
        newProducts[index] = { ...newProducts[index], qty: Number(qty) };
        return { ...prev, products: newProducts };
      });
    },
    add: (row, qty) => {
      setCart((prev) => {
        const existingIndex = prev.products.findIndex((p) => p.id === row.id);
        if (existingIndex >= 0) {
          const newProducts = [...prev.products];
          newProducts[existingIndex] = {
            ...newProducts[existingIndex],
            qty: newProducts[existingIndex].qty + Number(qty),
          };
          return { ...prev, products: newProducts };
        } else {
          return {
            ...prev,
            products: [...prev.products, { ...row, qty: Number(qty) }],
          };
        }
      });
    },
    total: () =>
      cart.products.reduce((sum, row) => {
        const brutoPrijs = parseFloat(
          row.brutoprijs.replace(/[.]/g, "").replace(",", ".")
        );
        return sum + brutoPrijs * row.qty;
      }, 0),
    addBrands: (brand) => {
      setBrands((prev) => {
        if (prev.includes(brand)) {
          return prev.filter((b) => b !== brand);
        } else {
          return [...prev, brand];
        }
      });
    },
    addCats: (cat) => {
      setCats((prev) => {
        if (prev.includes(cat)) {
          return prev.filter((c) => c !== cat);
        } else {
          return [...prev, cat];
        }
      });
    },
  };

  useEffect(() => {
    const getNotes = async () => {
      try {
        const notes = await apiClient.portalProducts.getNotes();
        const noteList = notes.data.map((a) => a.opmerkingen);
        setNotes(noteList.join("\n"));
      } catch (error) {
        console.error("Error fetching notes:", error);
      }
    };
    getNotes();
  }, []);

  return (
    <Wrapper style={{ backgroundColor: "#C1C1C199" }}>
      <Header
        searchTerm={setCurrentSearchTerm}
        products={cart.products}
        functions={functions}
      />

      <ListContainer>
        <Row>
          <CustomWidth width={"200px"}>
            <FilterPanel
              products={products}
              addCats={functions.addCats}
              addBrands={functions.addBrands}
            />
          </CustomWidth>
          <AutoWidth>
            {isAuthenticated ? (
              <PortalProductListing
                brands={brands}
                cats={cats}
                products={products}
                functions={functions}
                searchTerm={currentSearchTerm}
              />
            ) : (
              <Title style={{ paddingTop: "40%" }}>
                Log in om toegang te krijgen tot het Media Service Portal.
              </Title>
            )}
          </AutoWidth>
          <CartDrawer
            className="modal"
            side="right"
            open={cart.open}
            closeDrawer={functions.openCart}
            style={{}}
          >
            <OrderList state={cart} functions={functions} />
          </CartDrawer>

          <NoteDrawer
            className="modal"
            side="right"
            open={note.open}
            closeDrawer={functions.openNote}
          >
            <Note>
              <strong>Release Notes: </strong>
              <br />
              <br />
              {notes.length !== 0 ? (
                notes
              ) : (
                <Skeleton count={10} height={10} borderRadius={"7px"} />
              )}
            </Note>
          </NoteDrawer>

          <FilterDrawer
            className="modal"
            side="right"
            open={filter.open}
            closeDrawer={functions.openFilter}
          >
            <FilterPanel
              products={products}
              addCats={functions.addCats}
              addBrands={functions.addBrands}
            />
          </FilterDrawer>
        </Row>
      </ListContainer>
    </Wrapper>
  );
}

export default Landing;
