import React from "react";

import styled from "styled-components";

import { Container } from "./Container";
import { Title } from "./Title";
import { PrimaryButton } from "./PrimaryButton";
import { SecondaryButton } from "./SecondaryButton";
import { InputField } from "./InputField";
import { TextArea } from "./TextArea";
import { IconButton } from "./IconButton";

import { colors, spacing } from "./Tokens";

const StyledDrawer = styled.div<{
  position?: string;
}>`
  background-color: #fff;
  max-width: 100%;
  position: fixed;
  overflow: visible;
  z-index: 3;
  color: ${colors.purple};

  ${({ position }) => {
    if (position === "bottom") {
      return `
        left: 0;
        right: 0;
        bottom: -100%;
        transition: bottom .3s;
        min-height: 10rem;
        padding: ${spacing.normal};
      `;
    } else {
      return `
        width: 60rem;
        top: 0;
        bottom: 0;
        right: -100%;
        transition: right .3s;
      `;
    }
  }};

  &.active {
    ${({ position }) => {
      if (position === "bottom") {
        return `
          bottom: 0;
        `;
      } else {
        return `
          right: 0;
        `;
      }
    }};

    + .backdrop {
      opacity: 0.8;
      pointer-events: all;
    }
  }
`;

const StyledFilterDrawer = styled.div<{
  position?: string;
}>`
  background-color: ${colors.pink};
  max-width: 50%;
  position: fixed;
  overflow: scroll;
  z-index: 3;
  color: ${colors.purple};

  ${({ position }) => {
    if (position === "bottom") {
      return `
        left: 0;
        right: 0;
        bottom: -100%;
        transition: bottom .3s;
        min-height: 10rem;
        padding: ${spacing.normal};
      `;
    } else {
      return `
        width: 60rem;
        top: 0;
        bottom: 0;
        right: 100%;
        transition: right .3s;
      `;
    }
  }};

  &.active {
    ${({ position }) => {
      if (position === "bottom") {
        return `
          bottom: 0;
        `;
      } else {
        return `
          right: 0;
        `;
      }
    }};

    + .backdrop {
      opacity: 0.8;
      pointer-events: all;
    }
  }
`;

const StyledContactDrawer = styled(StyledDrawer)`
  text-align: center;
`;

const DrawerContainer = styled.div`
  width: 60%;
  margin: 0 auto;
  padding-top: 10rem;
`;

const ContactForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    align-self: flex-end;
  }
`;

const Backdrop = styled.div`
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: currentColor;
  backdrop-filter: blur(2px);
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
`;

interface DrawerProps {
  children?: React.ReactChild | React.ReactChild[];
  open: boolean;
  closeDrawer: (open: boolean) => void;
  color: keyof typeof colors;
  side?: string;
}

export const Drawer = ({ children, open, closeDrawer, color }: DrawerProps) => {
  return (
    <>
      <StyledDrawer className={!!open ? "active" : ""} position="bottom">
        <Container>{children}</Container>
        <IconButton
          onClick={() => closeDrawer(!open)}
          open={open}
          type="close"
          style={{
            position: "absolute",
            top: "-4.5rem",
            right: "2rem",
            backgroundColor: "#FFF",
            margin: 0,
          }}
        />
      </StyledDrawer>
      <Backdrop className="backdrop" style={{ color: colors[color] }} />
    </>
  );
};

export const CartDrawer = ({
  children,
  open,
  closeDrawer,
  color,
  side,
}: DrawerProps) => {
  if (!!open) {
    document.body.classList.add("overlay-open");
  } else {
    document.body.classList.remove("overlay-open");
  }

  return (
    <>
      <StyledDrawer
        className={!!open ? "active" : ""}
        position={side || "bottom"}
      >
        <Container>{children}</Container>
        <IconButton
          onClick={() => closeDrawer(!open)}
          open={open}
          type="close"
          size="small"
          color="pink"
          style={{
            position: "absolute",
            top: "0.8rem",
            right: "2rem",
            margin: 0,
          }}
        />
      </StyledDrawer>
      <Backdrop
        onClick={() => closeDrawer(!open)}
        className="backdrop"
        style={{ color: colors[color] }}
      />
    </>
  );
};

export const FilterDrawer = ({
  children,
  open,
  closeDrawer,
  color,
  side,
}: DrawerProps) => {
  if (!!open) {
    document.body.classList.add("overlay-open");
  } else {
    document.body.classList.remove("overlay-open");
  }

  return (
    <>
      <StyledFilterDrawer
        className={!!open ? "active" : ""}
        position={side || "bottom"}
      >
        <Container>{children}</Container>
        <IconButton
          onClick={() => closeDrawer(!open)}
          open={open}
          type="close"
          size="small"
          color="pink"
          style={{
            position: "absolute",
            top: "0.8rem",
            right: "4rem",
            margin: 0,
          }}
        />
      </StyledFilterDrawer>
      <Backdrop
        onClick={() => closeDrawer(!open)}
        className="backdrop"
        style={{ color: colors[color] }}
      />
    </>
  );
};

export const NoteDrawer = ({
  children,
  open,
  closeDrawer,
  color,
  side,
}: DrawerProps) => {
  if (!!open) {
    document.body.classList.add("overlay-open");
  } else {
    document.body.classList.remove("overlay-open");
  }

  return (
    <>
      <StyledDrawer
        style={{ backgroundColor: colors.pink }}
        className={!!open ? "active" : ""}
        position={side || "bottom"}
      >
        <Container>{children}</Container>

        <IconButton
          onClick={() => closeDrawer(!open)}
          open={open}
          type="close"
          size="small"
          color="pink"
          style={{
            position: "absolute",
            top: "0.8rem",
            right: "4rem",
            margin: 0,
          }}
        />
      </StyledDrawer>
      <Backdrop
        onClick={() => closeDrawer(!open)}
        className="backdrop"
        style={{ color: colors[color] }}
      />
    </>
  );
};

export const ContactDrawer = ({
  children,
  open,
  closeDrawer,
  color,
}: DrawerProps) => {
  const [page, changePage] = React.useState(1);

  return (
    <>
      <StyledContactDrawer className={!!open ? "active" : ""} position="right">
        {children}
        <DrawerContainer>
          <Title style={{ textTransform: "none" }}>
            Vragen?
            <br />
            <span style={{ color: colors.purple }}>Neem contact op</span>
          </Title>
          {page === 1 ? (
            <>
              <PrimaryButton onClick={() => changePage(2)}>
                E-mail ons
              </PrimaryButton>
              <br />
              of
              <br />
              <SecondaryButton>Bel 043-4070700</SecondaryButton>
            </>
          ) : (
            ""
          )}
          {page === 2 ? (
            <ContactForm>
              <InputField
                type="text"
                placeholder="Uw e-mailadres"
                size="36"
                color="white"
              />
              <TextArea color="white" size="36" placeholder="Bericht" />
              <PrimaryButton>Verstuur</PrimaryButton>
            </ContactForm>
          ) : (
            ""
          )}
        </DrawerContainer>
        <IconButton
          onClick={() => {
            closeDrawer(!open);
            changePage(1);
          }}
          open={open}
          type="close"
          style={{
            position: "absolute",
            top: "2rem",
            right: "2rem",
          }}
        />
      </StyledContactDrawer>
      <Backdrop className="backdrop" style={{ color: colors[color] }} />
    </>
  );
};
