import { useEffect, useState } from "react";
import styled from "styled-components";
import parse from "html-react-parser";

import { useAuth0 } from "@auth0/auth0-react";

import { svgs, colors } from "./Tokens";

import { InputField } from "./InputField";
import { PrimaryButton } from "./PrimaryButton";
import { SecondaryButton } from "./SecondaryButton";
import { IconButton } from "./IconButton";
import { SubTitle } from "./Title";

import { InvoiceGenerator } from "./InvoiceGenerator";

// Set the number format
const c = new Intl.NumberFormat("nl-NL", {
  style: "currency",
  currency: "EUR",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const StyledOrderList = styled.div`
  width: 100%;
  height: 100vh;
  padding-bottom: 274px;
  background-color: ${colors.purple};
  display: flex;
  flex-direction: column;

  input,
  button {
    height: 40px;
    font-size: 16px;
    padding: 0 1.2rem;
    margin: 0;
    text-align: center;
  }

  button {
    white-space: nowrap;
    margin-left: 1rem;
    border-width: 2px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

const OrderListBody = styled.div`
  background-color: #eee;
  overflow: auto;
  height: calc(100% -300px);

  &:empty::after {
    content: "Uw lijst is leeg";
    padding: 2rem;
    display: block;
    font-size: 1.4rem;
  }
`;

const OrderListFooter = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
`;

const OrderListItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 1rem 2rem;
  border-bottom: 1px solid #d8d8d8;
  font-size: 1.6rem;
  flex-wrap: wrap;

  button.product__button--remove {
    height: 2.4rem;
    width: 2.4rem;
    min-width: 2.4rem;
    border: none;
    background-color: ${colors.purple};
    color: #fff;

    span {
      line-height: 0;
    }
  }

  > div {
    &.product__details {
      display: flex;
      flex: 1 1 100%;
      font-size: 1.2rem;
    }
    &.product__form {
      display: flex;

      input {
        height: 3rem;
      }

      > div {
        margin: 0;
        position: static;
        flex: 1;

        &.product__price {
          flex: 4;
          font-size: 1.4rem;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
        }
      }
    }
  }
`;

const OrderList = ({ title, button, state, ...props }) => {
  const [generateInvoice, setGenerateInvoice] = useState(false);
  const { isAuthenticated } = useAuth0();

  const total = props.functions.total();
  const formattedTotalWithCurrency = c.format(total);
  const [assembly, setAssembly] = useState(0);

  // Function to handle assembly change
  const handleAssemblyChange = (e) => {
    const assemblyValue = parseInt(e.target.value); // Convert string to number
    setAssembly(assemblyValue); // Update the assembly state
  };

  const handleGenerateInvoice = () => {
    setGenerateInvoice(true);
  };

  const handleInvoiceGenerated = () => {
    setGenerateInvoice(false);
    setCounter();
  };

  // Inside your functional component
  const [shipping, setShipping] = useState(null);

  useEffect(() => {
    const calculateShipping = () => {
      if (assembly === 0) {
        return 18;
      } else {
        return 0;
      }
    };

    const newShipping = calculateShipping();
    setShipping(newShipping);
  }, [state, assembly]);

  const finalTotal =
    parseFloat(total) < 1500
      ? c.format(parseFloat(total) + shipping)
      : formattedTotalWithCurrency;

  function setCounter() {
    var counter = localStorage.getItem("counter");
    if (counter === null) {
      counter = 0;
    } else {
      counter++;
    }
    localStorage.setItem("counter", counter);
  }

  return (
    <StyledOrderList>
      <SubTitle
        style={{
          padding: "0 2rem",
          height: "6rem",
          minHeight: "6rem",
          display: "flex",
          alignItems: "center",
          background: "#fff",
          borderBottom: `1px solid ${colors.purple}`,
        }}
      >
        <h2 style={{ margin: "0", fontSize: "2.4rem" }}>Bestelling</h2>
      </SubTitle>
      <OrderListBody>
        {state.products.map((row, index) => {
          return (
            <OrderListItem key={index}>
              <div className="product__details">
                <SubTitle style={{ fontSize: "1.4rem", marginBottom: "1rem" }}>
                  {row.omschrijving}
                  <br />
                  <span
                    style={{
                      fontSize: "1.2rem",
                      color: colors.purple,
                      fontWeight: "400",
                    }}
                  >
                    Merk: {row.merk}
                  </span>
                </SubTitle>
                <IconButton
                  className="product__button--remove"
                  onClick={() => props.functions.remove(index)}
                >
                  {parse(svgs.cross)}
                </IconButton>
              </div>
              <div className="product__form">
                <InputField
                  defaultValue={row.qty}
                  key={`input_${index}-${row.qty}`}
                  color="purple"
                  style={{ margin: 0 }}
                  onChange={(e) => {
                    if (!!e.target.value) {
                      props.functions.update(index, e.target.value);
                    }
                  }}
                />
                <div className="product__price">
                  €
                  {(
                    row.qty *
                    parseFloat(
                      row.brutoprijs.replace(/[.]/g, "").replace(",", ".")
                    )
                  )
                    .toLocaleString("nl-NL", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                    .trim()
                    .replace(/\s/g, " ")}
                </div>
              </div>
            </OrderListItem>
          );
        })}
      </OrderListBody>
      <OrderListFooter>
        <div
          style={{
            display: "inline-block",
            verticalAlign: "middle",
            backgroundColor: "#fff",
            fontSize: "1.4rem",
            padding: "2rem",
          }}
        >
          <label>
            <textarea
              style={{
                backgroundColor: "#eee",
                outline: "none",
                height: "6rem",
                width: "100%",
                display: "flex",
                alignItems: "left",
                resize: "none",
                border: "none",
                marginBottom: "1rem",
              }}
              type="text"
              placeholder="Projectomschrijving"
              defaultValue={state.description}
              onChange={(e) => (state.description = e.target.value)}
            />
          </label>
          <label htmlFor="montage">Montage? *</label>
          <input
            type="radio"
            value={1}
            name="montage"
            style={{ height: "auto", marginLeft: "2rem" }}
            onChange={handleAssemblyChange} // Update state on change
            checked={assembly === 1} // Checked if assembly is 1
          />
          Ja
          <input
            type="radio"
            value={0}
            name="montage"
            style={{ height: "auto", marginLeft: "2rem" }}
            onChange={handleAssemblyChange} // Update state on change
            checked={assembly === 0} // Checked if assembly is 0
          />
          Nee
          <h3
            style={{
              display: "flex",
              justifyContent: "right",
              marginTop: "auto",
            }}
          >
            Totaal + Levering: {finalTotal}
          </h3>
          {assembly === 0 && (
            <p
              style={{
                display: "flex",
                justifyContent: "right",
                marginTop: "auto",
                color: "grey",
              }}
            >
              De leveringskosten bedragen €18 bij bestellingen onder de €1500
            </p>
          )}
          <p
            style={{
              display: "flex",
              justifyContent: "left",
              fontSize: "1rem",
              marginTop: "auto",
              color: "grey",
            }}
          >
            * De installatiekosten zijn slechts indicatief; voor definitieve
            besluitvorming dienen de installatiekosten gecontroleerd te worden.
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginLeft: "-1rem",
            }}
          >
            <SecondaryButton
              color="pink"
              style={{ backgroundColor: "#261127" }}
              onClick={() => props.functions.clear()}
            >
              Wissen
            </SecondaryButton>
            `{" "}
            <PrimaryButton
              disabled={state.products.length <= 0 && !isAuthenticated}
              onClick={handleGenerateInvoice}
              color="pink"
            >
              Offerte genereren
            </PrimaryButton>
            {generateInvoice && (
              <InvoiceGenerator
                state={state}
                assembly={assembly}
                onGenerate={handleInvoiceGenerated}
              />
            )}
            `
          </div>
        </div>
      </OrderListFooter>
    </StyledOrderList>
  );
};

export { OrderList };
